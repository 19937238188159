@import "../../../app/App";

.pdf-viewer__container-legacy {
  height: 100%;
  width: calc(100vw - $transition-view-width - $side-bar-width);

  .annotator-container-legacy {
    background-color: $annotator-background;
    height: 100%;

    .annotator-pages-container {
      height: 100%;
      width: 100%;
    }

    .table-builder__border-icon {
      width: auto !important;
      height: auto !important;
    }
  }

  .controls__container-legacy {
    background-color: $annotator-background;
    border-bottom: 1px $white-color solid;
    margin-bottom: 15px;
  }
}