.page {
  display: flex;
  justify-content: safe center;

  &:not(:last-child) {
    padding-bottom: 40px;
  }

  .page__container {
    position: relative;

    .page__number_item {
      position: absolute;
      top: 50%;
      left: 0;
      width: 10px;
      height: 10px;
      opacity: 0;
    }

    .cursor-text {
      z-index: 20;
      position: absolute;
      border-radius: 10px;
      padding: 3px 10px;
      font-family: sans-serif;
      overflow: hidden;
      white-space: nowrap;
    }

    .page__canvas-container {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    .page__text-layer-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      line-height: 1.2;

      .token-container {
        position: absolute;
        white-space: pre-wrap;
        cursor: inherit;
        transform-origin: 0 0;
        padding: 0;
      }
    }

    .area-annotation__container {
      position: absolute;

      .area-annotation__text-input {
        padding: 8px 12px;
        background-color: #ffffff;
        border: 1px solid #e1e4e8;
        border-radius: 2px;
        width: calc(100% - 22px);
        min-width: 100px;
        margin-bottom: 2px;

        &:focus {
          outline: 0;
          background-color: #fff;
          box-shadow: 0 0 0 2px #e8f0f0;
        }

        &.hidden {
          visibility: hidden;
        }
      }

      .area-annotation__mark {
        position: relative;
        cursor: pointer;
        background-color: transparent;

        .area-annotation__toggle-icon {
          position: absolute;
          top: -12px;
          left: -12px;
          width: 16px;
          height: 16px;
          cursor: pointer;
          background-color: #f7f7f7;
          border-radius: 100%;
          padding: 2px;
          z-index: 1;
          border: 1px solid rgba(0, 0, 0, 0.3);
          opacity: 0.8;
        }

        span {
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0.2;
        }
      }
    }


    .table-builder__container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      .table-builder__border {
        position: absolute;

        .table-builder__border-icon {
          cursor: pointer;
          visibility: hidden;
          position: absolute;
          background-color: #fff;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 20px;
          border: 3px solid #69c0ff;
          z-index: 11;


          &:hover {
            visibility: visible;
          }
        }

        &:hover {
          & > .table-builder__border-icon {
            visibility: visible;
          }
        }

        .link-table-button__container {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          border: 3px solid #69c0ff;
          background: white;
          padding: 2px;
          border-radius: 50%;

          &:active {
            transform: scale(0.95);
          }

          &:after {
            top: 20px;
            left: calc(50% - 2px);
            width: 0;
            content: '';
            position: absolute;
            display: block;
            height: 10px;
            border: 2px solid #69c0ff;
          }

          .link-table-button__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }
        }
      }

      .table-builder__border-trash-icon {
        position: absolute;
        border-radius: 50%;
        background-color: #f7f7f7;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 5px 6px;
        cursor: pointer;
      }


      .table-builder__table {
        position: absolute;
        display: grid;
        border-bottom: 3px solid #69c0ff;
        border-right: 3px solid #69c0ff;
      }

      .exclude-button__container {
        position: absolute;
        left: -32px;
        border-radius: 50%;
        background-color: #f7f7f7;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 2px 3px;
        cursor: pointer;
        display: flex;

        span {
          font-size: 12px !important;
        }

        &:active {
          transform: scale(0.95);
        }

        span {
          font-size: 8px;
        }
      }

      .excluded-row__container {
        position: absolute;
        opacity: 0.6;
        background: repeating-linear-gradient(
                        45deg,
                        #B0BEC5,
                        #B0BEC5 10px,
                        #78909C 10px,
                        #78909C 20px
        );
      }

      .col {
        border-right: 3px solid #69c0ff;
        position: absolute;

        &.dragging {
          border-right: 5px solid #69c0ff;
        }
      }

      .row {
        border-bottom: 3px solid #69c0ff;
        position: absolute;

        &.dragging {
          border-bottom: 5px solid #69c0ff;
        }
      }

      .table-line__remove-button {
        color: #69c0ff;
        position: absolute;
        z-index: 15;
        cursor: pointer;
      }
    }
  }
}