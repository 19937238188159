.selection-container {
  &.annotating-mode {
    user-select: none;
    cursor: crosshair;
  }

  &.text-selection-mode {
    cursor: text;

    &::-moz-selection {
      background: rgba(0, 0, 255, 0.2);
      color: transparent;
    }

    &::selection {
      background: rgba(0, 0, 255, 0.2);
      color: transparent;
    }
  }

  &.normal-mode {
    cursor: default;
  }

  .selection__rectangle {
    opacity: 0.3;
    border: 1px dashed #000;
    position: absolute;
  }
}