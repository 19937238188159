@import "../app/App";

.pdf-viewer__container {
  height: 100%;
  width: calc(100vw - $transition-view-width - $side-bar-width);

  .annotator-container {
    background-color: $annotator-background;
    position: relative;
    height: calc(100% - 60px);
    box-sizing: border-box;

    .annotator-pages-container {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      padding: 30px 50px;
    }

    .table-builder__border-icon {
      width: auto !important;
      height: auto !important;
    }
  }

  .controls__container {
    z-index: 20;
    background-color: $annotator-background;
    border-bottom: 1px $white-color solid;
    margin-bottom: 0;
  }
}